.side-nav {
	height: 100%;
	width: 250px;
	position: fixed;
	z-index: 1;
	top: 50px;
	left: 0;
	background-color: #111;
	overflow-x: hidden;
	padding: 20px;
}

.side-nav__menu-items {
	padding: 6px 8px 6px 16px;
	text-decoration: none;
	font-size: 20px;
	color: #818181;
	display: block;
	font-size: 20px;
}

.side-nav__menu-items:hover {
	color: #f1f1f1;
	text-decoration: none;
}

.side-nav__menu-items.active {
	color: #f1f1f1;
	text-decoration: none;
}

.main {
	margin-left: 250px;
	padding: 0px 10px;
}
