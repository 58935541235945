.top-nav {
  display: flex;
  align-items: center;
  width: 100%;
  position: fixed;
  height: 50px;
  background-color: #367fa9;
  z-index: 110;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3);
}

.top-nav__user-menu-icon {
  color: white;
  margin-left: auto;
  font-size: 20px;
  padding: 15px 30px;
  cursor: pointer;
}

.top-nav__logo {
  padding: 10px 30px;
  height: 50px;
}

.user-navigation-menu {
  top: 50px;
  width: 250px;
  border-radius: 0 0 5px 5px;
  background-color: white;
  margin-left: auto;
  position: fixed;
  right: 5px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3);
  padding: 10px;
  text-align: center;
}

.user-info span {
  padding: 10px 5px;
  color: grey;
  display: block;
  text-align: center;
  overflow-wrap: break-word;
}

.logout-btn {
  background-color: #9c9b9b;
  width: 100%;
  height: 40px;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
  border-radius: 5px;
}

.logout-btn:hover {
  background-color: #7d7d7d;
}

/* Style page content */
.main {
  margin-top: 50px; /* Same as the height of the topNav */
}
