@import '../Main.scss';

.trans-status {
  text-transform: uppercase;
  padding: 0.4rem 0.8rem;
  color: $white;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  font-weight: 500;
  margin-right: 0.5rem;
}

.trans-status.canceled,
.trans-status.returned,
.trans-status.refunded,
.trans-status.hold {
  background: $grey;
}
.trans-status.completed {
  background: $light-green;
}
.trans-status.processed,
.trans-status.pending {
  background: $yellow;
}
.trans-status.failed {
  background: $strawberry;
}
.trans-status.created,
.trans-status.initiated {
  background: $light-blue;
}

.delivery-status {
  text-transform: uppercase;
  padding: 0.4rem 0.8rem;
  color: $white;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  font-weight: 500;
  margin-right: 0.5rem;
}

.delivery-status.hold {
  background: $grey;
}
.delivery-status.delivered,
.delivery-status.delivery_payout_ready {
  background: $light-green;
}
.delivery-status.pending,
.delivery-status.delivery_requested {
  background: $yellow;
}
.delivery-status.failed {
  background: $strawberry;
}
.delivery-status.requested {
  background: $light-blue;
}

.basic-transaction-detail-container,
.complete-transaction-detail-container,
.transaction-history-container {
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 40px;
  border-bottom: 1px solid #4e4e4e;
}

.sender-detail,
.recipient-detail,
.transaction-detail {
  flex-grow: 1;
}

.transaction-history {
  padding-top: 20px;
  padding-bottom: 10px;
  margin-left: 20px;
  border-bottom: 1px solid #6fb4e2;
}

.transaction-history .status {
  font-size: 20px;
  font-weight: bold;
  color: #6fb4e2;
  padding-right: 10px;
}

.transaction-history .history-date {
  color: #8f8e8e;
  font-size: 14px;
}

.transaction-history .history-comment-container {
  padding: 10px;
  font-weight: bold;
}

.transaction-history .history-comment {
  display: block;
  color: #8f8e8e;
  font-size: 14px;
}

.text-align-right {
  text-align: right;
}

.display-flex {
  display: flex;
}

.display-block {
  display: block;
}

.transaction-detail-container {
  padding: 20px;
}

.basic-transaction-detail {
  flex-grow: 1;
}

.detail-list {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 14px;
}

.detail-list .heading {
  font-weight: bold;
}

.padding-bottom-20 {
  padding-bottom: 20px;
}

.txn-filter__button-wrapper {
  display: flex;
  justify-content: flex-end;
}

.txn-filter__button {
  margin: 5px;
}

.txn-filter {
  display: flex;
  padding: 10px 0;
}

.txn-filter__element {
  width: 25%;
  display: inline-block;
  padding: 0 10px;
}

.txn-export__container {
  display: flex;
  align-items: center;
}

.txn-export__button {
  color: #367fa9;
  margin: 10px 0px 10px auto;
  margin: 10px 0px 10px auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.txn-export__loader {
  height: 1rem;
  width: 1rem;
  margin-left: 5px;
}

.txn-export__field-selector {
  height: 300px;
  overflow: auto;
  padding: 20px;
  border: 2px solid #c0c0c0;
  border-radius: 5px;
}

.txn-export__btn-container {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}
