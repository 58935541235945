$white: #ffffff;
$grey: #9b9b9b;
$red: #d0021b;
$purple: #d750f2;
$light-green: #2fca6b;
$yellow: #f2c61f;
$green: #417505;
$strawberry: #ee5d6f;
$light-blue: #18a0b3;

.wrapper {
  display: flex;
  width: 100%;
}

.content-container {
  margin-top: 50px;
  width: 100%;
  margin-left: 250px;
  padding: 20px;
}

.toggle-filter__wrapper {
  display: flex;
  justify-content: center;
}

.toggle-filter__button {
  cursor: pointer;
  color: #367fa9;
  padding-bottom: 20px;
}

.toggle-filter__button:hover {
  color: #61a5cb;
}
