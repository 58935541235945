.modal-container {
  padding: 20px 30px;
}

.change-txn-status__button-wrapper {
  display: flex;
  justify-content: flex-end;
}

.change-txn-status__button {
  margin: 5px;
}
