.modal-wrapper {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 90;
  padding: 20px;
  padding-top: 70px;
  padding-left: 270px;
}

.modal-background {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 95;
  background: #a6aaad;
  opacity: 0.5;
}

.modal-content {
  max-height: 100%;
  z-index: 99;
}

.modal-content .container {
  overflow: auto;
}

.modal-top__close-btn {
  margin-left: auto;
  padding: 10px;
  cursor: pointer;
}

.modal-top {
  display: flex;
}
