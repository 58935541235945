.page-item {
  cursor: pointer;
}
.page-item.disabled {
  pointer-events: none;
}

.data-table {
  font-size: 0.8rem;
}

.info-table__key {
  width: 25%;
  text-align: right;
  font-weight: bold;
}

.display-single-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  border: none !important;
}

.display-single-line:hover {
  overflow: visible;
}

.data-unavailable__td {
  text-align: center;
  border: none;
}

.data-unavailable__span {
  color: grey;
  font-size: 0.9rem;
}

.clickable-row {
  cursor: pointer;
}
