.horizontal-nav {
  height: 50px;
  display: flex;
  align-items: center;
  overflow: hidden;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
  border-radius: 5px;
}

.tablinks {
  background-color: inherit;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 17px;
  text-decoration: none;
  color: black;
}
.tablinks:hover {
  background-color: #ddd;
  text-decoration: none;
  color: black;
}
.tablinks.active {
  background-color: #ccc;
}
