@import '../Main.scss';

.approval-status {
  text-transform: uppercase;
  padding: 0.4rem 0.8rem;
  color: $white;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  font-weight: 500;
  margin-right: 0.5rem;
}

.approval-status.approved {
  background: $light-green;
}
.approval-status.required {
  background: $strawberry;
}

.affiliate-form__button-wrapper {
  display: flex;
  justify-content: flex-end;
}

.affiliate-form__button {
  margin: 5px;
}
