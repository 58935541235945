.document-view {
  height: 500px;
  padding: 20px;
}

.tier-verification-action__button-wrapper {
  display: flex;
  justify-content: flex-end;
}

.tier-verification-action__button {
  margin: 5px;
}
