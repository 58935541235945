@import '../Main.scss';

.user-status {
  text-transform: uppercase;
  padding: 0.4rem 0.2rem;
  color: $white;
  border-radius: 0.25rem;
  font-size: 0.7rem;
  font-weight: 500;
  margin-right: 0.5rem;
}

.user-status.document,
.user-status.retry {
  background: $grey;
}
.user-status.reupload {
  background: $purple;
}
.user-status.verified {
  background: $light-green;
}
.user-status.in_progress,
.user-status.review_pending,
.user-status.document_requested {
  background: $yellow;
}
.user-status.manual_retry,
.user-status.suspended,
.user-status.unverified {
  background: $strawberry;
}
.user-status.receive {
  background: $light-blue;
}

.sender-filter__button-wrapper {
  display: flex;
  justify-content: flex-end;
}

.sender-filter__button {
  margin: 5px;
}

.sender-filter {
  display: flex;
  padding: 10px 0;
}

.sender-filter__element {
  width: 25%;
  display: inline-block;
  padding: 0 10px;
}

.sender-export__container {
  display: flex;
  align-items: center;
}

.sender-export__button {
  color: #367fa9;
  margin: 10px 0px 10px auto;
  margin: 10px 0px 10px auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sender-export__loader {
  height: 1rem;
  width: 1rem;
  margin-left: 5px;
}

.sender-export__container {
  display: flex;
  align-items: center;
}

.sender-export__button {
  color: #367fa9;
  margin: 10px 0px 10px auto;
  margin: 10px 0px 10px auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sender-export__loader {
  height: 1rem;
  width: 1rem;
  margin-left: 5px;
}

.sender-export__field-selector {
  height: 300px;
  overflow: auto;
  padding: 20px;
  border: 2px solid #c0c0c0;
  border-radius: 5px;
}

.sender-export__btn-container {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}
