.loader-wrapper {
  position: relative;
}

.loader {
  position: absolute;
  height: 100%;
  width: 100%;
  background: #a6aaad;
  opacity: 0.5;
}

.fixed-loader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: #a6aaad;
  opacity: 0.5;
}

.spinner-center {
  left: 50%;
  margin: -1rem 0 -1rem 0;
  top: 50%;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
  position: absolute;
}

.spinner {
  width: 100%;
  height: 100%;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
  display: flex;
}
